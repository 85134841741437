<template>
  <v-text-field
    outlined
    v-model="v"
    :label="model.title"
    :error-messages="error"
    :disabled="model.disabled || config.disabled || false"
    :hide-details="config.hideDetails || false"
    :dense="config.dense || false"
    :readonly="readonly"
    :prefix="model.prefix"
    @input_="$emit('input', $event)"
    @keypress="filter($event)"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    values: Object,
  },
  computed: {
    v: {
      get() {
        // Если первые три символа "996", убираем их
        if (this.model.prepend)
          if ((this.value || "").startsWith(this.model.prepend)) {
            return this.value.slice(this.model.prepend.length);
          }
        if (this.model.format) {
          let format = [/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5-$6"];
          return this.value.replace(format[0], format[1]);
        }
        return this.value;
      },
      set(v) {
        let result = (this.model.prepend || "") + `${v}`;
        //  if (typeof result === "string" && result) result = parseInt(result);

        this.$emit("input", result);
      },
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    isDisable() {
      return this.model?.disabled || this.disabled;
    },
  },
  methods: {
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[0-9]*\.?\,?[0-9]*$/.test(expect)) {
        evt.preventDefault();
        //evt = "";
        //return true;
      } else {
        return true;
      }
    },
  },
};
</script>
